import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Menu, MenuItem, Table, TableBody, TableCell, TableRow } from "@mui/material";

import STATUS from "constants/status";

import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppConvertWorkOrderModal from "components/pages/work-inspection-listing/app-convert-work-order-modal";
import AppAddExistingAssetsModal from "components/pages/work-inspection-listing/app-add-existing-assets-modal";
import AppReportDetailsCreateEditModal from "components/pages/work-inspection-listing/app-report-details-create-edit-modal";
import AppWorkInspectionConfirmDeleteAssetModal from "components/pages/work-inspection-listing/app-work-inspection-confirm-delete-asset-modal";

import editIcon from "assets/images/edit-icon.png";
import addIcon from "assets/images/blue-add-icon.svg";
import removeIcon from "assets/images/trash-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppReportDetailsAssetTable = (props) => {
	const siteId = useMemo(() => props.siteId, [props.siteId]);
	const status = useMemo(() => props.status, [props.status]);
	const isContract = useMemo(() => props.isContract, [props.isContract]);
	const onHandleRemoveAsset = useMemo(() => props.onHandleRemoveAsset, [props.onHandleRemoveAsset]);
	const onHandleUpdateWIRAssets = useMemo(() => props.onHandleUpdateWIRAssets, [props.onHandleUpdateWIRAssets]);
	const wirAssets = useMemo(() => props.wirAssets?.map((o, i) => ({ ...o, paginationNumbers: i + 1 })), [props.wirAssets]);
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);
	const isBeforeInProgress = useMemo(() => props.isBeforeInProgress, [props.isBeforeInProgress]);
	const [selectedAssetIds, setSelectedAssetIds] = useState([]);
	const [data, setData] = useState({ content: wirAssets });
	const [menuAnchor, setMenuAnchor] = useState(null);
	const selectedItem = useRef();
	const reportDetailsAssetRef = useRef();
	const addExistingAssetsModalRef = useRef();
	const confirmDeleteAssetModalRef = useRef();
	const convertWorkOrderModalRef = useRef();

	const onHandleCloseMenu = useCallback(() => {
		selectedItem.current = null;

		setMenuAnchor(null);
	}, []);

	// prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		setMenuAnchor(event.currentTarget);
	}, [data]);

	// prettier-ignore
	const onHandleRemoveReportDetailsAsset = useCallback((tableMeta) => {
		if (isContract) selectedItem.current = { ...data.content[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		confirmDeleteAssetModalRef.current.onHandleShow(selectedItem.current);

		onHandleCloseMenu();
	}, [data.content, onHandleCloseMenu, isContract]);

	const onHandleAddReportDetailsAsset = useCallback(() => {
		if (isContract) {
			addExistingAssetsModalRef.current.onHandleShow(wirAssets);
		} else {
			reportDetailsAssetRef.current.onHandleShow(selectedItem.current);
		}

		onHandleCloseMenu();
	}, [isContract, onHandleCloseMenu, wirAssets]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		const ids = data?.content?.map((item) => item.id);

		setSelectedAssetIds(boolean ? ids : []);
	}, [data]);

	const onHandleSelectAssetId = useCallback((boolean, idNo) => {
		setSelectedAssetIds((prev) => {
			let nextAssetIds = [...prev];

			if (boolean) {
				nextAssetIds.push(idNo);
			} else {
				nextAssetIds = nextAssetIds.filter((id) => id !== idNo);
			}

			return nextAssetIds;
		});
	}, []);

	const onHandleShowConvertWorkOrderModal = useCallback(() => {
		const assetsToConvert = selectedAssetIds.map((id) => data.content.filter((o) => o.id === id)[0]);

		convertWorkOrderModalRef.current.onHandleShow(assetsToConvert);
	}, [data.content, selectedAssetIds]);

	const AddAsset = useCallback((obj) => {
		return <AppButton outline type="button" label="Add Assets" icon={obj.addIcon} onClick={obj.onHandleAddReportDetailsAsset} />;
	}, []);

	const ConvertWorkOrder = useCallback((obj) => {
		if (obj.selectedAssetIds.length) {
			return <AppButton outline type="button" label="Convert to Work Order" onClick={obj.onHandleShowConvertWorkOrderModal} />;
		}
	}, []);

	//prettier-ignore
	const ActionButton = useCallback((obj) => {
		if (obj.status === STATUS.VERIFIED) {
			return <ConvertWorkOrder selectedAssetIds={obj.selectedAssetIds} onHandleShowConvertWorkOrderModal={obj.onHandleShowConvertWorkOrderModal} />;
		} else if (obj.siteId || !isContract) {
			return <AddAsset addIcon={obj.addIcon} siteId={obj.siteId} onHandleAddReportDetailsAsset={obj.onHandleAddReportDetailsAsset} />;
		}
	}, [isContract]);

	const tableFirstColumn = useMemo(() => {
		if (status === STATUS.VERIFIED) {
			return {
				name: "checkbox",
				options: {
					sort: false,
					customHeadRender: () => {
						const selectedAll = data.content.length > 0 && selectedAssetIds.length === data.content.length;

						return (
							<TableCell key="table-key" className="app-table__cell app-table__cell--header">
								<AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />
							</TableCell>
						);
					},
					customBodyRender: (value, tableMeta) => {
						const rowIndex = tableMeta.rowIndex;
						const tableAssetId = data.content[rowIndex]?.id;
						const selected = selectedAssetIds.includes(tableAssetId);

						return (
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className="app-table__cell">
											<AppCheckbox className="checkbox" onClick={(v) => onHandleSelectAssetId(v, tableAssetId)} value={selected} />
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						);
					}
				}
			};
		}

		return {
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		};
	}, [data.content, onHandleSelectAll, onHandleSelectAssetId, selectedAssetIds, status]);

	const tableAction = useMemo(() => {
		if (isContract) {
			return {
				name: "remove",
				label: "Remove",
				options: {
					sort: false,
					display: isBeforeInProgress ? "true" : "excluded",
					customBodyRender: (value, tableMeta) => {
						return (
							<button type="button" className="table__action" onClick={() => onHandleRemoveReportDetailsAsset(tableMeta)}>
								<img src={removeIcon} alt="remove-icon" />
							</button>
						);
					}
				}
			};
		}

		return {
			name: "actions",
			label: "Actions",
			options: {
				sort: false,
				display: isBeforeInProgress ? "true" : "excluded",
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		};
	}, [isBeforeInProgress, isContract, onHandleRemoveReportDetailsAsset, onToggleTableAction]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		tableFirstColumn,
		{
			name: "assetName",
			label: "Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "customerAssetName",
			label: "Customer Asset Name",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "assetType",
			label: "Asset Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "workspace",
			label: "Workspace",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		tableAction
	], [tableAction, tableFirstColumn]);

	useEffect(() => {
		const wirAssetsCopy = structuredClone(wirAssets);

		setData({ content: wirAssetsCopy });
	}, [wirAssets]);

	return (
		<div className="app-report-details-asset-table">
			<div className="report-details-asset-table__header">
				<p className="report-details-asset-table__label report-details-asset-table__label--table">Assets</p>

				{status !== STATUS.CANCELLED && <ActionButton onHandleAddReportDetailsAsset={onHandleAddReportDetailsAsset} onHandleShowConvertWorkOrderModal={onHandleShowConvertWorkOrderModal} selectedAssetIds={selectedAssetIds} siteId={siteId} status={status} addIcon={addIcon} />}
			</div>

			<div className="report-details-asset-table">
				<AppTable data={data.content} columns={tableColumns} />
			</div>

			<AppAddExistingAssetsModal ref={addExistingAssetsModalRef} siteId={siteId} onHandleUpdateWIRAssets={onHandleUpdateWIRAssets} onHandleGetDetails={onHandleGetDetails} />

			<AppReportDetailsCreateEditModal ref={reportDetailsAssetRef} siteId={siteId} wirAssets={wirAssets} onHandleUpdateWIRAssets={onHandleUpdateWIRAssets} />

			<AppWorkInspectionConfirmDeleteAssetModal ref={confirmDeleteAssetModalRef} isContract={isContract} onHandleRemoveAsset={onHandleRemoveAsset} />

			<AppConvertWorkOrderModal ref={convertWorkOrderModalRef} />

			<Menu classes={{ root: "table-menu" }} anchorEl={menuAnchor} open={!!menuAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleAddReportDetailsAsset}>
					<img src={editIcon} alt="asset-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleRemoveReportDetailsAsset}>
					<img src={removeIcon} alt="asset-remove" />
					Remove
				</MenuItem>
			</Menu>
		</div>
	);
};

export default AppReportDetailsAssetTable;
